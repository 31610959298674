<template>
  <div>
    <Icon
      :iconName="currentIcon.iconName"
      :size="size"
      :color="currentIcon.color"
    />
    <div v-if="containsDescription">
      {{ currentIcon.description }}
    </div>
  </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
import { iconList } from '@/assets/icons/iconList'
export default {
  name: 'DeviceIcon',
  components: {
    Icon
  },
  props: {
    device: String,
    size: String,
    containsDescription: Boolean
  },
  computed: {
    currentIcon: function () {
      return {
        iconName: iconList[this.device].icon,
        color: iconList[this.device].color,
        description: iconList[this.device].text
      }
    }
  }
}
</script>
