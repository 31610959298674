<template>
  <b-card class="edit-container__container-card mb-4 card-shadow no-pointer">
    <div class="edit-button top-right" @click.stop="redirectToEdit">
      <Icon color="grey" iconName="cog" size="4px" />
    </div>
    <div
      v-if="!getTrashAbility"
      class="remove-button top-left"
      @click.stop="removeContainer"
    >
      <Icon
        :isBootstrap="true"
        color="grey"
        iconName="trash-fill"
        size="15px"
      />
    </div>
    <b-card-header>
      <v-form>
        <v-text-field
          v-model="item.name"
          :rules="[nameValidation]"
          class="editable-card-title"
          dense
          filled
          @blur="checkChildInput(item.name, 'blur', null)"
          @keydown.enter="checkChildInput(item.name, 'enter', $event.target)"
          @keydown.esc="revertChild($event.target)"
        />
      </v-form>
    </b-card-header>
    <div style="padding-top: 10px; height: 100%;">
      <LocationIcon
        v-if="item.renderingMetadata && item.renderingMetadata.iconName"
        :location="getLocation(item.renderingMetadata.iconName)"
        size="12px"
      />
      <div v-if="areGrandchildren">
        <div
          v-for="grandchild in item.children.filter(
            gc => !gc.renderingMetadata || !gc.renderingMetadata.deviceId
          )"
          :key="`${keyPrefix}-${grandchild.id}`"
          class="edit_container__container-card__grandchild"
          style="margin: 0 auto; width: 75%; max-width: 300px;"
        >
          <v-form>
            <v-text-field
              v-model="grandchild.name"
              :rules="[nameValidation]"
              dense
              @blur="
                checkGrandchildInput(
                  grandchild.name,
                  grandchild.id,
                  'blur',
                  $event.target
                )
              "
              @keydown.enter="
                checkGrandchildInput(
                  grandchild.name,
                  grandchild.id,
                  'enter',
                  $event.target
                )
              "
              @keydown.esc="revertGrandchild(grandchild.id, $event.target)"
            />
          </v-form>
        </div>
      </div>
      <br />
    </div>
  </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Icon from '@/components/elements/Icon'
import LocationIcon from '@/components/elements/LocationIcon'
export default {
  name: 'EditContainerCard',
  props: {
    item: Object,
    keyPrefix: Number,
    nameValidation: Function
  },
  components: {
    Icon,
    LocationIcon
  },
  data () {
    return {
      prevChildName: null,
      prevGrandchildNames: {},
      areGrandchildren: null
    }
  },
  computed: {
    ...mapGetters({
      getContainerById: 'containers/getContainerById'
    }),
    getTrashAbility () {
      const thisContainer = this.getContainerById(this.item?.id)
      if (thisContainer) {
        const { deviceIds, childrenIds, nestedContainerIds } = thisContainer
        return (
          !!deviceIds?.length ||
          !!nestedContainerIds?.length ||
          !!childrenIds?.length
        )
      }
      return false
    }
  },
  methods: {
    getLocation (container) {
      return container.renderingMetadata?.iconName || 'room'
    },
    checkChildInput (name, eventType, input) {
      name = name.trim()
      if (name === this.prevChildName) {
        if (input) input.blur()
        return
      }
      if (!name) {
        if (eventType === 'enter') return
        if (eventType === 'blur') this.revertChild(input)
      }
      if (name) {
        this.$emit('updateContainerContents', name)
        this.prevChildName = name
      }
      if (input) input.blur()
    },
    revertChild (input) {
      this.item.name = this.prevChildName
      if (input) input.blur()
    },
    checkGrandchildInput (name, id, eventType, input) {
      name = name.trim()
      if (name === this.prevGrandchildNames[id]) {
        input.blur()
        return
      }
      if (!name) {
        if (eventType === 'enter') return
        if (eventType === 'blur') this.revertGrandchild(id)
      }
      if (name) {
        this.$emit('updateContainerContents', name)
        this.prevGrandchildNames[id] = name
      }
      if (input) input.blur()
    },
    revertGrandchild (id, input) {
      const grandchild = this.item.children.find(child => child.id === id)
      grandchild.name = this.prevGrandchildNames[id]
      if (input) input.blur()
    },
    redirectToEdit () {
      this.$emit('redirectToEdit', this.item.id)
    },
    removeContainer () {
      this.$emit('removeContainer', this.item.id)
    }
  },
  mounted () {
    this.prevChildName = this.item.name
    // Get grandchild containers excluding device containers
    const grandchildContainers = this.item.children?.filter(grandchild => {
      return (
        !grandchild.renderingMetadata || !grandchild.renderingMetadata.deviceId
      )
    })
    if (grandchildContainers.length) {
      for (const gc of grandchildContainers) {
        this.prevGrandchildNames[gc.id] = gc.name
      }
      this.areGrandchildren = true
    }
  }
}
</script>
