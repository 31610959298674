<template>
  <div class="edit-container__wrapper">
    <div class="tri-center-grid">
      <v-btn
        class="ma-2"
        light
        style="color: white; margin: auto; background: dodgerblue; min-width: 6rem;"
        variant="info"
        @click.stop="returnToPrevious"
      >
        <v-icon left> mdi-arrow-left</v-icon>
        {{ $t('buttons.back') }}
      </v-btn>
      <h2>{{ $t('editing.editTitle') }}</h2>
      <v-btn
        :disabled="initialDisabledState || getTrashAbility"
        class="ma-2"
        color="error"
        style="width: 5rem; transition: all 0.7s ease;"
        @click.stop="removeThisContainer"
      >
        {{ $t('buttons.delete') }}
      </v-btn>
    </div>

    <div class="edit-container__card-wrapper">
      <v-card class="mx-auto" elevation="2">
        <div class="edit-container__card-topbar">
          <div class="name-input-wrapper text-left">

            <EditPhenoms :updatePhenoms="updatePhenoms" :renderingMetadata="container.renderingMetadata" :devices="container.devices" :childsDevices="childDeviceContainers" />
            <v-text-field
              :ref="container.id"
              v-model="container.name"
              :rules="[validationRules.name]"
              filled
              style="font-size: 1.7rem;"
              @blur="checkContainerName(container.name, 'blur', $event.target)"
              @keydown.enter="
                checkContainerName(container.name, 'enter', $event.target)
              "
              @keydown.esc="revertContainerName($event.target)"
            />
          </div>
        </div>
        <v-container class="edit-container__main-content-wrapper">
          <v-row>
            <v-col class="edit-container__devices-wrapper">
              <div
                class="edit-container__col-title-wrapper d-flex justify-content-center align-items-center"
              >
                <h3 class="edit-container__col-title" style="margin-bottom: 0;">
                  {{ $t('devices.title') }}
                </h3>
                <div
                  class="edit-container__button-wrapper--add hover-zoom"
                  @click="showAddDeviceCard"
                >
                  <Icon color="inherit" icon-name="plus" size="8px" />
                </div>
              </div>

              <div class="d-flex flex-column">
                <transition name="fade">
                  <div
                    v-if="isShowingAddDeviceCard"
                    class="d-flex justify-content-center"
                  >
                    <AddDeviceCard
                      :deviceOptions="deviceOptions"
                      :device-validation="validationRules.device"
                      @addDevice="addDevice"
                      @createDeviceContainer="createDeviceContainer"
                      @hideAddDeviceCard="hideAddDeviceCard"
                    />
                  </div>
                </transition>
                <div
                  v-if="childDeviceContainers && childDeviceContainers.length"
                  class="device-containers"
                >
                  <div
                    v-for="child in childDeviceContainers"
                    :key="`${keyPrefix}-${child.id}`"
                  >
                    <EditDeviceContainerCard
                      :item="child"
                      :name-validation="validationRules.name"
                      @removeContainer="removeContainer"
                      @updateContainerContents="updateContainerContents"
                    />
                  </div>
                </div>
                <div
                  v-if="container.devices && container.devices.length"
                  class="devices"
                >
                  <b-card
                    v-for="device in container.devices"
                    :key="`${keyPrefix}-${device ? device.id : '1'}`"
                    class="edit-container__device-card mb-4 card-shadow no-pointer"
                  >
                    <DeviceCard :item="device" @removeDevice="removeDevice" />
                  </b-card>
                </div>
                <div>
                  <div v-if="!areDevices && !isShowingAddDeviceCard">
                    {{ $t('devices.noDevices') }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col class="edit-container__containers-wrapper">
              <div
                class="edit-container__col-title-wrapper d-flex justify-content-center align-items-center"
              >
                <h3 class="edit-container__col-title" style="margin-bottom: 0;">
                  {{ $t('containers.title') }}
                </h3>
                <div
                  class="edit-container__add-button-wrapper hover-zoom"
                  @click="showAddContainerCard"
                >
                  <Icon
                    class="edit-container__button-wrapper--add"
                    color="inherit"
                    icon-name="plus"
                    size="7px"
                  />
                </div>
              </div>

              <transition name="fade">
                <div
                  v-if="isShowingAddContainerCard"
                  class="new-container-form-wrapper d-flex justify-content-center"
                >
                  <AddContainerCard
                    :name-validation="validationRules.name"
                    @createNewContainer="createNewContainer"
                    :hideAddContainerCard="hideAddContainerCard"
                  />
                </div>
              </transition>

              <div v-if="childContainers && childContainers.length">
                <div
                  class="row-order d-flex flex-column justify-content-center"
                  v-for="child in childContainers"
                  :key="`${keyPrefix}-${child.id}`"
                >
                  <EditContainerCard
                    :item="child"
                    :keyPrefix="keyPrefix"
                    :name-validation="validationRules.name"
                    @redirectToEdit="redirectToEdit"
                    @removeContainer="removeContainer"
                    @updateContainerContents="updateContainerContents"
                  />
                </div>
              </div>
              <div
                v-if="
                  (!childContainers || !childContainers.length) &&
                    !isShowingAddContainerCard
                "
              >
                {{ $t('containers.noChildren') }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AddDeviceCard from '@/components/edit/AddDeviceCard'
import EditDeviceContainerCard from '@/components/edit/EditDeviceContainerCard'
import DeviceCard from '@/components/edit/DeviceCard'
import AddContainerCard from '@/components/edit/AddContainerCard'
import EditContainerCard from '@/components/edit/EditContainerCard'
import Icon from '@/components/elements/Icon'
import { cloneDeep } from 'lodash'
import TreeModel from 'tree-model-improved'
import EditPhenoms from '@/components/edit/EditPhenoms'
const treeModel = new TreeModel({
  modelComparatorFn: (a, b) => a.name?.toLowerCase() > b.name?.toLowerCase()
})

export default {
  name: 'EditContainer',
  components: {
    AddDeviceCard,
    EditDeviceContainerCard,
    DeviceCard,
    AddContainerCard,
    EditContainerCard,
    Icon,
    EditPhenoms
  },
  data () {
    return {
      keyPrefix: 0,
      prevContainerName: '',
      tree: {},
      containerNode: {},
      container: {},
      error: null,
      deviceOptions: [],
      isShowingAddContainerCard: false,
      isShowingAddDeviceCard: false,
      initialDisabledState: true,
      validationRules: {
        name: value => {
          return !!value?.trim() || 'Required'
        },
        device: container => {
          return !!container?.renderingMetadata?.deviceId
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getDevices: 'devices/getDevices',
      getRegisteredDevices: 'devices/getRegisteredDevices',
      getSelectedContainer: 'containers/getSelectedContainer',
      getContainers: 'containers/getContainers',
      getContainerById: 'containers/getContainerById',
      getMyIds: 'login/getMyIds'
    }),
    childDeviceContainers () {
      const childDeviceContainers = this.container.children?.filter(
        child => !!child.renderingMetadata?.deviceId
      )
      return childDeviceContainers || []
    },
    childContainers () {
      const childContainers = this.container.children?.filter(
        child => !child.renderingMetadata?.deviceId
      )
      return childContainers || []
    },
    areDevices () {
      return (
        !!this.container?.devices?.length ||
        !!this.childDeviceContainers?.length
      )
    },
    getTrashAbility () {
      const thisContainer = this.getContainerById(this.container?.id)
      if (thisContainer) {
        const { deviceIds, childrenIds, nestedContainerIds } = thisContainer
        return (
          !!deviceIds?.length ||
          !!nestedContainerIds?.length ||
          !!childrenIds?.length
        )
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      fetchDevices: 'devices/fetchDevices',
      fetchRegisteredDevices: 'devices/fetchRegisteredDevices',
      updateContainer: 'containers/updateContainer',
      fetchContainer: 'containers/fetchContainer',
      clearSelectedContainer: 'containers/clearSelectedContainer'
    }),
    getLocation (container) {
      return container.renderingMetadata?.iconName || 'room'
    },
    showAddContainerCard () {
      this.isShowingAddContainerCard = true
    },
    showAddDeviceCard () {
      this.isShowingAddDeviceCard = true
    },
    hideAddContainerCard () {
      this.isShowingAddContainerCard = false
    },
    hideAddDeviceCard () {
      this.isShowingAddDeviceCard = false
    },
    redirectToEdit (id) {
      this.hideAddDeviceCard()
      this.hideAddContainerCard()
      this.keyPrefix++ // changes key when updating currently viewed container to prevent duplicate key error
      this.$router.push({
        name: 'EditContainer',
        params: { id: id }
      })
    },
    returnToPrevious () {
      this.$router.back()
    },
    async createNewContainer (data) {
      data.customerId = this.getMyIds?.CUSTOMER?.[0] ?? 'DEFAULT'
      data.fulfillerId = this.getMyIds?.FULFILLER?.[0] ?? 'DEFAULT'
      data.renderingMetadata = {
        deviceId: null,
        iconName: null
      }
      this.containerNode.addChild(treeModel.parse(data))
      try {
        await this.updateStateContainer('container_create', data)
        // now update container has been called
        this.hideAddContainerCard()
      } catch (err) {
        console.log(err)
      }
    },
    async createDeviceContainer (data) {
      // Add available info to data
      data.customerId = this.getMyIds?.CUSTOMER?.[0] ?? 'DEFAULT'
      data.fulfillerId = this.getMyIds?.FULFILLER?.[0] ?? 'DEFAULT'
      const deviceId = data.renderingMetadata.deviceId
      // Add device and deviceId to new container
      const deviceToAdd = this.getDevices.find(device => device.id === deviceId)
      if (deviceToAdd) {
        data.deviceIds.push(deviceId)
        data.devices.push(deviceToAdd)
      }
      this.containerNode.addChild(treeModel.parse(data))

      try {
        // Send updated container tree to back end
        await this.updateStateContainer('container_create', data)
        // Update dropdown options
        this.deviceOptions = this.deviceOptions.filter(
          device => device.value !== data.renderingMetadata.deviceId
        )
        this.hideAddDeviceCard()
      } catch (err) {
        console.log(err)
      }
    },
    async updatePhenoms (checkBoxes) {
      let newContainer = cloneDeep(this.container)
      newContainer.renderingMetadata = { ...cloneDeep(this.container.renderingMetadata), ...checkBoxes }
      console.log('updatePhenoms', newContainer.renderingMetadata)
      await this.updateContainer({
        container: this.tree.model,
        msgType: 'container_update',
        updatedObj: newContainer,
        name: 'Phenomenoms'
      })
    },
    async updateContainerContents (name) {
      this.container.customerId =
        this.container.customerId || this.getMyIds?.CUSTOMER?.[0]
      this.container.fulfillerId =
        this.container.fulfillerId || this.getMyIds?.FULFILLER?.[0]
      this.updateStateContainer(
        'container_update',
        cloneDeep(this.container),
        name
      )
    },
    async updateStateContainer (msgType, updatedObj, name) {
      await this.updateContainer({
        container: this.tree.model,
        msgType,
        updatedObj,
        name
      })
    },
    removeContainer (id) {
      let containerToDrop = this.tree.first(node => node.model.id === id)
      if (containerToDrop) {
        try {
          containerToDrop.drop()
          this.updateStateContainer('remove_container', containerToDrop.model) // send container to update in state & local
        } catch (err) {
          console.log(err)
        }
      }
    },
    async removeThisContainer () {
      await this.removeContainer(this.container.id)
      this.$router.go(-1) // navigate to previous page
    },
    async addDevice (deviceId) {
      const deviceToAdd = this.getDevices.find(device => device.id === deviceId) // find Device
      if (deviceToAdd) {
        this.containerNode.model.devices.push(deviceToAdd)
        for (const device of this.containerNode.model.devices) {
          if (
            !this.containerNode.model.deviceIds.some(id => id === device.id)
          ) {
            this.containerNode.model.deviceIds.push(device.id)
          }
        }
        this.deviceOptions = this.deviceOptions.filter(
          device => device.value !== deviceId
        ) // Update Dropdown Options
        try {
          await this.updateStateContainer('device_add', deviceToAdd) // send container to update in state & local
          this.hideAddDeviceCard()
        } catch (err) {
          console.log(err)
        }
      }
    },
    async removeDevice (deviceId) {
      const removedDevice = this.containerNode.model.devices.filter(
        device => device.id === deviceId.id
      )
      this.deviceOptions.push({
        value: removedDevice[0].id,
        label: removedDevice[0]?.metadata?.serialNumber ?? this.$t('devices.noDeviceSerialNumber')
      })
      try {
        const foundDevice = this.containerNode.model.devices?.findIndex(
          device => device.id === deviceId.id
        )
        const foundDeviceId = this.containerNode.model.deviceIds?.findIndex(
          id => id === deviceId.id
        )
        if (foundDevice > -1) {
          this.containerNode.model.devices.splice(foundDevice, 1)
        }
        if (foundDeviceId > -1) {
          this.containerNode.model.deviceIds.splice(foundDeviceId, 1)
        }

        await this.updateStateContainer('remove_device', deviceId)
      } catch (err) {
        console.log(err)
      }
    },
    checkContainerName (name, eventType, input) {
      name = name.trim()
      if (name === this.prevContainerName) {
        if (input) input.blur()
        return
      }
      if (!name) {
        if (eventType === 'enter') return
        if (eventType === 'blur') this.revertContainerName(input)
      }
      if (name) {
        this.updateContainerContents()
        this.prevContainerName = name
      }
      if (input) input.blur()
    },
    revertContainerName (input) {
      this.container.name = this.prevContainerName
      if (input) input.blur()
    },
    async editInit () {
      if (this.$route?.params) {
        try {
          this.tree = treeModel.parse(cloneDeep(this.getContainers))
          const params = this.$route.params
          this.containerNode = this.tree.first(node => {
            return node.model.id === params.id
          })
          // Get child container data
          this.container = this.containerNode.model
          if (this.container) {
            this.container.children = this.container.children || []
            if (
              this.container?.childrenIds?.length &&
              this.container?.children === 0
            ) {
              // check this logic
              for (const childId of this.container?.childrenIds) {
                if (
                  !this.container.children.some(node => node.id === childId)
                ) {
                  const child = this.tree.first[
                    node => node.model.id === childId
                  ]
                  if (child) this.container.children.push(child)
                }
              }
            }
          }
          this.fetchDevices()
          this.prevContainerName = this.container.name
        } catch (err) {
          console.log(err)
        }
      }
    }
  },
  mounted () {
    this.editInit()
    this.clearSelectedContainer() // makes sure selectedContainer is cleared (if navigating from slideDrawer)
    this.initialDisabledState = false // workaround for default enabled button state causing unwanted flicker effect in the brief space before getTrashAbility returns false
  },
  watch: {
    getContainers (newVal) {
      this.editInit()
    },
    getDevices (newVal, oldVal) {
      if (newVal && Array.isArray(newVal)) {
        this.deviceOptions = newVal.map(device => ({
          value: device.id,
          label: device?.metadata?.serialNumber ?? this.$t('devices.noDeviceSerialNumber')
        }))
      }
    },
    containerNode (newVal, oldVal) {
      this.container = this.containerNode.model
    },
    container (newVal, oldVal) {
      if (!newVal) {
        this.error =
          this.$t('containers.noContainerIdFound') + this.$route?.params?.id
      }
    },
    $route (to, from) {
      this.editInit()
    }
  }
}
</script>
