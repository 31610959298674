var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"edit-container__container-card mb-4 card-shadow no-pointer"},[_c('div',{staticClass:"edit-button top-right",on:{"click":function($event){$event.stopPropagation();return _vm.redirectToEdit($event)}}},[_c('Icon',{attrs:{"color":"grey","iconName":"cog","size":"4px"}})],1),(!_vm.getTrashAbility)?_c('div',{staticClass:"remove-button top-left",on:{"click":function($event){$event.stopPropagation();return _vm.removeContainer($event)}}},[_c('Icon',{attrs:{"isBootstrap":true,"color":"grey","iconName":"trash-fill","size":"15px"}})],1):_vm._e(),_c('b-card-header',[_c('v-form',[_c('v-text-field',{staticClass:"editable-card-title",attrs:{"rules":[_vm.nameValidation],"dense":"","filled":""},on:{"blur":function($event){return _vm.checkChildInput(_vm.item.name, 'blur', null)},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.checkChildInput(_vm.item.name, 'enter', $event.target)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.revertChild($event.target)}]},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1)],1),_c('div',{staticStyle:{"padding-top":"10px","height":"100%"}},[(_vm.item.renderingMetadata && _vm.item.renderingMetadata.iconName)?_c('LocationIcon',{attrs:{"location":_vm.getLocation(_vm.item.renderingMetadata.iconName),"size":"12px"}}):_vm._e(),(_vm.areGrandchildren)?_c('div',_vm._l((_vm.item.children.filter(
          function (gc) { return !gc.renderingMetadata || !gc.renderingMetadata.deviceId; }
        )),function(grandchild){return _c('div',{key:(_vm.keyPrefix + "-" + (grandchild.id)),staticClass:"edit_container__container-card__grandchild",staticStyle:{"margin":"0 auto","width":"75%","max-width":"300px"}},[_c('v-form',[_c('v-text-field',{attrs:{"rules":[_vm.nameValidation],"dense":""},on:{"blur":function($event){return _vm.checkGrandchildInput(
                grandchild.name,
                grandchild.id,
                'blur',
                $event.target
              )},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.checkGrandchildInput(
                grandchild.name,
                grandchild.id,
                'enter',
                $event.target
              )},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.revertGrandchild(grandchild.id, $event.target)}]},model:{value:(grandchild.name),callback:function ($$v) {_vm.$set(grandchild, "name", $$v)},expression:"grandchild.name"}})],1)],1)}),0):_vm._e(),_c('br')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }