<template>
  <b-card
    class="edit-container__device-card mb-4 card-shadow no-pointer"
  >
    <v-form ref="addDeviceForm">
      <div class="d-flex flex-column editable-card-title">
        <div class="device-container-info">
          <v-text-field
            v-model="newDeviceContainerData.name"
            :label="$t('forms.labels.deviceName')"
            class="editable-card-title"
          ></v-text-field>
          <transition name="fade">
            <div
              class="icon-selector-wrapper"
            >
              <v-select
                v-model="newDeviceContainerData.renderingMetadata.iconName"
                :clearable="!!newDeviceContainerData.renderingMetadata.iconName"
                :items="iconOptions"
                :placeholder="$t('devices.icon')"
                class="icon-selector"
                clear-icon="$close"
                item-text="label"
                item-value="icon"
                solo
              >
                <template v-slot:selection="{ item }">
                  <div class="option-icon">
                    <LocationIcon
                      :location="item.icon"
                      size="9px"
                    ></LocationIcon>
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div class="option-icon">
                    <LocationIcon
                      :location="item.icon"
                      size="9px"
                    ></LocationIcon>
                  </div>
                </template>
              </v-select>
            </div>
          </transition>
        </div>
        <v-select
          v-model="newDeviceContainerData.renderingMetadata.deviceId"
          :items="deviceOptions"
          :label="$t('features.deviceID')"
          :no-data-text="$t('devices.noDevicesAvailable')"
          :rules="[deviceValidation(newDeviceContainerData)]"
          class="mb-2"
          item-text="label"
          item-value="value"
          outlined
          style="margin-top: 5px;"
        >
        </v-select>
        <div class="d-flex justify-content-center">
          <button
            @click.prevent="handleAddDevice"
            class="edit-container__button-wrapper hover-zoom"
          >
            <Icon
              size="7px"
              icon-name="check-circle"
              color="green"
            />
          </button>
          <button
            @click="hideAddDeviceCard"
            class="edit-container__button-wrapper hover-zoom"
            type="button"
          >
            <Icon
              size="7px"
              icon-name="times-circle"
              color="red"
            />
          </button>
        </div>
      </div>
    </v-form>
  </b-card>
</template>

<script>
import Icon from '@/components/elements/Icon'
import LocationIcon from '@/components/elements/LocationIcon'
import { iconList } from '../../assets/icons/iconList'
const uuidv4 = require('uuid').v4

export default {
  name: 'AddDeviceCard',
  components: {
    Icon,
    LocationIcon
  },
  props: {
    deviceOptions: Array,
    deviceValidation: Function
  },
  data () {
    return {
      newDeviceContainerData: {
        id: uuidv4(),
        parentId: this.$route?.params?.id,
        name: '',
        deviceIds: [],
        devices: [],
        children: [],
        metadata: {
          name: '',
          status: '',
          text: ''
        },
        renderingMetadata: {
          deviceId: null,
          iconName: null // change how this is saved
        }
      }
    }
  },
  computed: {
    iconOptions () {
      let iconObj = []
      for (const icon in iconList) {
        if (iconList[icon]?.vuetify) {
          iconObj.push(iconList[icon])
        }
      }
      return iconObj
    },
    iconChoice () {
      return this.newDeviceContainerData.renderingMetadata.iconName
    }
  },
  methods: {
    async handleAddDevice () {
      // If no device selected, trigger error state
      this.$refs.addDeviceForm.validate()
      // ...and don't proceed
      if (!this.newDeviceContainerData.renderingMetadata.deviceId) {
        return
      }
      // If a name is added, create a device container
      if (this.newDeviceContainerData.name) {
        this.$emit('createDeviceContainer', this.newDeviceContainerData)
      }
      // If no name added, just add to the current container's devices
      if (!this.newDeviceContainerData.name) {
        this.$emit('addDevice', this.newDeviceContainerData.renderingMetadata.deviceId)
      }
    },
    hideAddDeviceCard () {
      this.$emit('hideAddDeviceCard')
    }
  },
  watch: {
    iconChoice (newVal) {
      console.log(newVal)
      if (this.iconOptions.some(el => el.text === this.newDeviceContainerData.name) || this.newDeviceContainerData.name === '') {
        console.log(this.iconOptions)
        const newName = this.iconOptions.filter(el => el.icon === newVal)
        console.log(newName[0].text)
        this.newDeviceContainerData.name = newName[0].text
      }
    }
  }
}
</script>
