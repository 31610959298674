<template>
  <div>
    <Edit />
  </div>
</template>

<script>
import Edit from '@/components/edit/EditContainer'
export default {
  components: {
    Edit
  }
}
</script>
