<template>
  <b-card
    class="edit-container__container-card mb-4 card-shadow no-pointer"
  >
    <b-card-header>
      <v-form ref="addContainerForm">
        <v-text-field
          v-model="newContainerData.name"
          :label="$t('forms.labels.containerName')"
          :rules="[nameValidation]"
          autofocus
          class="editable-card-title"
          dense
          filled
        ></v-text-field>
        <div class="new-container-buttons">
          <button
            class="edit-container__button-wrapper hover-zoom"
            type="submit"
            @click.prevent="createNewContainer"
          >
            <Icon
              color="green"
              icon-name="check-circle"
              size="7px"
            />
          </button>
          <button
            class="edit-container__button-wrapper hover-zoom"
            @click="hideAddContainerCard"
            type="button"
          >
            <Icon
              color="red"
              icon-name="times-circle"
              size="7px"
            />
          </button>
        </div>
      </v-form>
    </b-card-header>
  </b-card>
</template>

<script>
import Icon from '@/components/elements/Icon'
const uuidv4 = require('uuid').v4

export default {
  components: { Icon },
  props: {
    nameValidation: Function,
    hideAddContainerCard: Function
  },
  data () {
    return {
      newContainerData: {
        id: uuidv4(),
        parentId: this.$route?.params?.id,
        name: '',
        devices: [],
        children: [],
        metadata: {
          name: '',
          status: '',
          text: ''
        }
      }
    }
  },
  methods: {
    createNewContainer () {
      // If no name added, trigger error state
      this.$refs.addContainerForm.validate()
      // ...and don't proceed
      if (!this.newContainerData.name.trim()) return
      this.$emit('createNewContainer', this.newContainerData)
    }
  }
}
</script>
