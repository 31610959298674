<template>
  <div>
    <b-card class="edit-container__device-card mb-4 card-shadow no-pointer">
      <b-card-header v-if="item.status">
        <DeviceIcon
          :containsDescription="true"
          :device="item.status.type"
          size="10px"
        />
      </b-card-header>
      <div class="top-right show" @click.stop="removeContainer(item.id)">
        <Icon
          class="edit-container__button-wrapper hover-zoom"
          color="darkred"
          icon-name="times-circle"
          size="5px"
        />
      </div>
      <div class="device-container-info">
        <v-text-field
          v-model="item.name"
          :rules="[nameValidation]"
          class="editable-card-title"
          @blur="checkDeviceName(item.name, 'blur', $event.target)"
          @keydown.enter="checkDeviceName(item.name, 'enter', $event.target)"
          @keydown.esc="revertDeviceName($event.target)"
        >
        </v-text-field>
        <div class="icon-selector-wrapper">
          <v-select
            v-model="item.renderingMetadata.iconName"
            :clearable="!!item.renderingMetadata.iconName"
            :items="iconOptions"
            :placeholder="$t('devices.noIcon')"
            class="icon-selector mb-2"
            clear-icon="$close"
            solo
            @change="updateContainer(`${item.name} icon`)"
          >
            <template v-slot:selection="{ item }">
              <div class="option-icon">
                <LocationIcon size="9px" :location="item"></LocationIcon>
              </div>
            </template>
            <template v-slot:item="{ item }">
              <div class="option-icon">
                <LocationIcon size="9px" :location="item"></LocationIcon>
              </div>
            </template>
          </v-select>
        </div>
      </div>
      <div v-if="item.devices && item.devices[0]">
        <div v-if="getDeviceType(item.devices[0])">
          Type: {{ getDeviceType(item.devices[0]) }}
        </div>
        <div
          v-if="item.devices[0].metadata.serialNumber"
        >
          {{ $t('features.sku') }}:
          {{ item.devices[0].metadata.serialNumber }}
        </div>
        <b-card-footer v-if="false"></b-card-footer>
      </div>
    </b-card>
  </div>
</template>

<script>
import Icon from '@/components/elements/Icon'
import LocationIcon from '@/components/elements/LocationIcon'
import DeviceIcon from '@/components/elements/DeviceIcon'
import { deviceTypeCodes } from '@/utils/deviceTypeCodes'
import { iconList } from '../../assets/icons/iconList'
export default {
  name: 'EditDeviceContainerCard',
  components: {
    Icon,
    DeviceIcon,
    LocationIcon
  },
  props: {
    item: Object,
    nameValidation: Function,
    updateContainerContents: Function
  },
  data () {
    return {
      name: '',
      prevName: '',
      iconName: ''
    }
  },
  computed: {
    iconOptions () {
      let iconObj = []
      for (const icon in iconList) {
        if (iconList[icon]?.vuetify) {
          iconObj.push(iconList[icon].icon)
        }
      }
      return iconObj
    }
  },
  methods: {
    getDeviceType (device) {
      // TODO: when there are more device types, use device metadata instead (attached when a new device is added)
      const deviceType = deviceTypeCodes[device.deviceTypeId].type
      return this.$t(`deviceType.${deviceType}`)
    },
    checkDeviceName (name, eventType, input) {
      name = name.trim()
      if (name === this.prevName) {
        if (input) input.blur()
        return
      }
      if (!name) {
        if (eventType === 'enter') return
        if (eventType === 'blur') this.revertDeviceName(input)
      }
      if (name) {
        this.updateContainer(name)
        this.prevName = name
      }
      if (input) input.blur()
    },
    revertDeviceName (input) {
      this.item.name = this.prevName
      if (input) input.blur()
    },
    removeContainer (id) {
      this.$emit('removeContainer', id)
    },
    updateContainer (name) {
      this.$emit('updateContainerContents', name)
    }
  },
  mounted () {
    this.name = this.item.name
    this.prevName = this.item.name
    this.iconName = this.item.renderingMetadata.iconName
  }
}
</script>
