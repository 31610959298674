<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="140"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ma-2"
          color="rgba(120,120,120,0.5)"
          dark
          v-bind="attrs"
          v-on="on"
          style="position: absolute; right: 10px; top: 0px; z-index: 4;"
        >
          <v-icon light>
            mdi-wrench
          </v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox
                name="presence"
                v-model="checkBoxes.presence"
                :value="renderingMetadata.presence || false"
              ></v-checkbox>
            </v-list-item-action>
          Presence/Absence
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-checkbox
                name="fall"
                v-model="checkBoxes.fall"
                :value="renderingMetadata.fall || false"
              ></v-checkbox>
            </v-list-item-action>
            Falls
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-checkbox
                name="online"
                v-model="checkBoxes.online"
                :value="renderingMetadata.online || false"
              ></v-checkbox>
            </v-list-item-action>
            Online/Offline
          </v-list-item>
        </v-list>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            text
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="menu = false"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: 'EditPhenoms',
  components: {
  },
  props: {
    renderingMetadata: Object,
    updatePhenoms: Function,
    devices: Array,
    childsDevices: Array
  },
  data: () => ({
    menu: false,
    checkBoxes: {
      online: true,
      fall: true,
      presence: true
    }
  }),
  methods: {
    async clickCheckbox (e) {
      console.log(e.target.name)
    }
  },
  watch: {
    checkBoxes: {
      async handler (newVal) {
        await this.updatePhenoms(newVal)
      },
      deep: true
    }
  }
}
</script>
