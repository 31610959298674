<template>
  <div>
    <b-card-header v-if="item && item.status">
      <DeviceIcon
        :containsDescription="true"
        :device="item && item.status && item.status.type"
        size="10px"
      />
    </b-card-header>
    <div
      class="top-right show"
      @click.stop="removeDevice"
    >
      <Icon
        class="edit-container__button-wrapper hover-zoom"
        color="darkred"
        icon-name="times-circle"
        size="5px"
      />
    </div>
    <div style="padding-top: 10px;">
      {{ $t('features.type') }}: {{ getDeviceType(item) }}
    </div>
    <div v-if="item && item.metadata">
      {{ $t('features.sku') }}:
      {{ item.metadata.serialNumber }}
    </div>
    <b-card-footer v-if="false"></b-card-footer>
  </div>
</template>
<script>
import DeviceIcon from '@/components/elements/DeviceIcon'
import Icon from '@/components/elements/Icon'
import { deviceTypeCodes } from '@/utils/deviceTypeCodes'
export default {
  components: {
    DeviceIcon,
    Icon
  },
  props: {
    item: Object
  },
  methods: {
    getDeviceType (device) {
      // TODO: when there are more device types, use device metadata instead (attached when a new device is added)
      if (device && device.deviceTypeId) {
        const deviceType = deviceTypeCodes[device.deviceTypeId].type
        return this.$t(`deviceType.${deviceType}`)
      }
    },
    removeDevice () {
      this.$emit('removeDevice', this.item)
    }
  }
}
</script>
